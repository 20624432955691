<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''"> -->
            <!-- <div class="CalculationResults"><span>计算结果</span></div> -->
            <!-- 具体标准 -->
            <!-- <div>
              <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>
            </div> -->
            <!--            <div>-->
            <!--              <div class="CalculationDetail"><span></span>具体标准：</div>-->
            <!--            </div>-->
          <!-- </div> -->
        </div>
      </div>
      <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      listSelect: [],
      countValue:"",
      countTextList:[],
      listData: [
        {
          title: '脉搏(次/min)',
          columns: ['<40','40-54','55-69','70-109','110-139','140-179' , '>179',],
          fenzhi: [4,3,2,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '收缩压(mmHg)',
          columns: ['<70', '70-89','90-129','130-149','150-179','>179'],
          fenzhi: [4,2,0,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '呼吸频率(次/min)',
          columns: ['<6' ,'6-9','10-11','12-24','25-34','35-49','>49'],
          fenzhi: [4,2,1,0,1,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: 'GCS(分)',
          columns: ['<5','5-7','8-10','11-13','>13',],
          fenzhi: [4,3,2,1,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
         {
          title: '年龄(岁)',
          columns: ['<45','45-54','55-64','65-74','>74'],
          fenzhi: [0,2,3,5,6] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
         {
          title: 'SpO₂(%)',
          columns: ['<75','75-85','86-89','>89',],
          fenzhi: [4,3,1,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['病死危险率10%', '病死危险率50%', '病死危险率100%'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateFiveList,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    listChange (result, fenzhi) {
      this.show=false
      this.listSelect = result //结果数组
      this.fenzhiList = fenzhi  //分值数组
    },
    coumputTetxt () {
      this.show=true
      let fenshu = 0
      for (let i = 0; i < this.fenzhiList.length; i++) {
        fenshu += this.fenzhiList[i]
      }
      let PAPS
      let REMS
      if (fenshu>=14) {
        PAPS ='RAPS：'+fenshu+'分 '
      } else if (fenshu === 8) {
        PAPS = 'RAPS：'+fenshu+'分 '
      } else if (fenshu <=7 ) {
        PAPS = 'RAPS：'+fenshu+'分 '
      }else {
        PAPS = 'RAPS：'+fenshu+'分 '
      }
      if (fenshu<=11) {
        REMS ='REMS：'+fenshu+'分 '
      } else if (fenshu >= 16 && fenshu <= 17) {
        REMS = 'REMS：'+fenshu+'分 '
      } else if (fenshu >=24 ) {
        REMS = 'REMS：'+fenshu+'分 '
      }else {
        REMS = 'REMS：'+fenshu+'分 '
      }

      this.result = fenshu
      this.countTextList = [PAPS,REMS]
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'MBIYDNNO',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.listSelect = res.data.content.data
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.fenzhiList = [res.data.result.value]
          this.result = res.data.result.value
          this.countText = res.data.result.result
          this.countTextList=res.data.result.result
          this.show=true
          // this.coumputTetxt()
        }
      }
    },
    async submit () {
      if (this.listSelect.length!==this.listData.length) {
        Toast.fail('请完善选择')
        return
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countTextList,
          DisplayResults:this.countTextList[0]+ "；" +this.countTextList[1]
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">

::v-deep .result2Style{
  justify-content: left!important;
}

.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.Abutton {

}
</style>
